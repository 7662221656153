import {
  DATE_RANGE_PICKER_OPTIONS,
  TRACKER_TABLE_COLUMNS,
  PRODUCT_CHART_LINES
} from 'constants/product_tracker/tracker'
import find from 'lodash/find'
import reject from 'lodash/reject'
import isEmpty from 'lodash/isEmpty'
import {
  addToCategory,
  categorize,
  getNumberOfCheckedRecommendedProducts,
  getNumberOfCheckedTracks,
  removeFromCategory,
  updateAllProductTracks,
  updateCategoryAverages,
  updateProductTrack,
  visibleCategory
} from 'helpers/tracker'
import {
  SELECT_GRAPH_DATE_RANGE,
  TOGGLE_GRAPH_Y_AXIS,
  SET_GRAPH_ACCESSORS
} from 'constants/action_types/tracker/side_panel'
import * as type from 'constants/action_types'
import {
  PENDING_TRACKER_CSV_IMPORT,
  COMPLETED_TRACKER_CSV_IMPORT
} from 'constants/action_types/tracker/csv'
import {
  ADD_PENDING_TRACKS,
  REMOVE_PENDING_TRACKS,
  RESET_PRODUCT_TRACKER
} from 'constants/action_types/tracker/tracking'

import {
  reSortItems,
  updateInArray,
  updateAllInArray,
  removeFromArray
} from 'helpers/arrays'
import { getShowState } from 'helpers/core'

export const initialState = {
  isLoading: false,

  activeTab: 'Overview',
  checkAll: false,
  numberChecked: 0,
  checkAllRecommended: false,
  numberCheckedRecommended: 0,
  categoryJustAddedTo: null,
  productJustAdded: false,
  viewTrackId: null,
  pendingCategory: null,

  trackedProducts: [],
  recommendedProducts: [],
  showDeleteConfirmation: null,
  deleteConfirmationData: null,

  selectedCountry: 'us',

  showReactivateModal: false,

  asin: '',
  category: '',
  productToAdd: {},

  notes: {
    showModal: false,
    product: {},
    trackerNotes: [],
    newNote: {
      subject: '',
      body: ''
    },
    selectedNote: {
      id: null,
      subject: '',
      body: ''
    }
  },

  groups: {
    selectedProducts: []
  },

  isGroupSelectorOpen: false,
  selectedMonths: DATE_RANGE_PICKER_OPTIONS[0].value,
  graphSelectedMonths: null,
  graphHideYAxis: true,
  graphAccessors: PRODUCT_CHART_LINES,
  overviewMaxDataRange: 30,

  createNewGroupStep: 1,

  productSearchPreview: {
    isLoading: false,
    completed: false,
    products: []
  },

  loadTracks: {
    isLoading: false,
    completed: false
  },

  loadCategory: {
    isLoading: false,
    completed: false
  },

  showMore: {
    isLoading: false,
    completed: false
  },

  addTrack: {
    isLoading: false,
    completed: false,
    pendingProduct: null
  },

  deleteTrack: {
    isLoading: false,
    completed: false
  },

  loadNotes: {
    isLoading: false,
    completed: false
  },

  saveNoteSubject: {
    isLoading: false,
    completed: false
  },

  saveNote: {
    isLoading: false,
    completed: false
  },

  deleteNote: {
    isLoading: false,
    completed: false
  },

  createCategory: {
    isLoading: false,
    completed: false
  },

  columns: TRACKER_TABLE_COLUMNS,
  selectedColumns: [],
  selectAllColumnsState: false,

  overviewSelectAllColumnsState: true,
  searchTerm: '',
  asinSearchTerm: '',
  asinNotFound: false,

  recommendedSort: {
    column: '',
    direction: ''
  },

  loadRecommended: {
    isLoading: false,
    completed: false
  },

  addRecommendedTrack: {
    isLoading: false,
    completed: false
  },

  asinSearch: {
    isLoading: false,
    completed: false
  },

  csvDownload: {
    isLoading: false,
    completed: false
  },

  productCsvDownload: {
    isLoading: false,
    completed: false
  },

  importCsv: {
    isLoading: false,
    error: null
  },

  pendingTracks: []
}

function tracker(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_TRACKER_ACTION:
      return {
        ...state,
        [action.payload]: {
          isLoading: true,
          completed: false
        }
      }
    case type.COMPLETED_TRACKER_ACTION:
      return {
        ...state,
        [action.payload]: {
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_TRACKER_ACTION:
      return {
        ...state,
        [action.payload]: {
          isLoading: false,
          completed: false
        }
      }
    case type.SET_TRACKER_DATA:
      return {
        ...state,
        ...action.payload
      }
    case type.REMOVE_TRACKER_NOTICE:
      return {
        ...state,
        productJustAdded: false,
        categoryJustAddedTo: null
      }
    case type.TOGGLE_REACTIVATE_FROM_TRACKER: {
      const newShowState = getShowState(
        state.showReactivateModal,
        action.payload
      )
      return {
        ...state,
        showReactivateModal: newShowState,
        addTrack: initialState.addTrack
      }
    }
    case type.CREATE_CATEGORY:
      if (action.stayOnCurrentTab) {
        return {
          ...state,
          trackedProducts: [...state.trackedProducts, action.payload]
        }
      }

      return {
        ...state,
        activeTab: action.payload.category_name,
        trackedProducts: [...state.trackedProducts, action.payload]
      }
    case type.EDIT_CATEGORY: {
      const updatedTrackedProducts = updateInArray(
        state.trackedProducts,
        { category_id: action.payload.id },
        action.payload.props
      )
      return {
        ...state,
        activeTab: action.payload.props.category_name,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.DELETE_CATEGORY: {
      const updatedTrackedProducts = reject(state.trackedProducts, {
        category_id: action.payload
      })
      return {
        ...state,
        activeTab: 'Ungrouped',
        showDeleteConfirmation: false,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.TRACKER_INPUT_FIELD_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value
      }
    case type.SORT_CATEGORY: {
      const categoryToSort = find(state.trackedProducts, {
        category_name: action.payload.categoryName
      })
      const updatedTrackedProducts = reSortItems(
        state.trackedProducts,
        action.payload.oldPosition,
        action.payload.newPosition,
        categoryToSort
      )
      return {
        ...state,
        categoryJustAddedTo: action.payload.categoryName,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.PENDING_ADD_PRODUCT_TRACK:
      return {
        ...state,
        addTrack: {
          ...state.addTrack,
          isLoading: true,
          pendingProduct: action.payload
        }
      }
    case type.COMPLETED_ADD_PRODUCT_TRACK:
      return {
        ...state,
        asin: initialState.asin,
        productToAdd: initialState.productToAdd,
        asinSearchTerm: initialState.asinSearchTerm,
        asinNotFound: initialState.asinNotFound,
        addTrack: {
          ...state.addTrack,
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_ADD_PRODUCT_TRACK:
      return {
        ...state,
        addTrack: initialState.addTrack
      }
    case type.ADD_PRODUCT_TO_CATEGORY: {
      const updatedTrackedProducts = addToCategory(
        state.trackedProducts,
        action.payload.categoryName,
        action.payload.product
      )

      const { product } = action.payload
      const lastAddedProductId = Array.isArray(product)
        ? product[0].id
        : product.id

      return {
        ...state,
        activeTab: action.payload.categoryName,
        productJustAdded: lastAddedProductId,
        trackedProducts: updatedTrackedProducts,
        asin: ''
      }
    }
    case type.TOGGLE_DELETE_CONFIRMATION:
      return {
        ...state,
        showDeleteConfirmation: !!action.payload?.record,
        deleteConfirmationData: action.payload
      }
    case type.DELETE_PRODUCT_TRACK: {
      const updatedTrackedProducts = removeFromCategory(
        state.trackedProducts,
        state.activeTab,
        action.payload
      )

      return {
        ...state,
        trackedProducts: updatedTrackedProducts,
        asinSearchTerm: initialState.asinSearchTerm
      }
    }
    case type.BATCH_DELETE_PRODUCT_TRACKS: {
      const updatedTrackedProducts = removeFromCategory(
        state.trackedProducts,
        state.activeTab,
        action.payload
      )

      return {
        ...state,
        trackedProducts: updatedTrackedProducts,
        asinSearchTerm: initialState.asinSearchTerm
      }
    }
    case type.BATCH_CATEGORIZE_PRODUCT_TRACKS: {
      const updatedTrackedProducts = categorize(
        state.trackedProducts,
        state.activeTab,
        action.payload.categoryName,
        action.payload.products
      )

      const newActiveTab = isEmpty(state.asinSearchTerm)
        ? state.activeTab
        : action.payload.categoryName
      return {
        ...state,
        trackedProducts: updatedTrackedProducts,
        categoryJustAddedTo: action.payload.categoryName,
        activeTab: newActiveTab,
        asinSearchTerm: initialState.asinSearchTerm
      }
    }
    case type.TOGGLE_CHECK_PRODUCT_TRACK: {
      const newCheckedState = getShowState(action.payload.checked)
      const category = visibleCategory(state)

      const updatedTrackedProducts = updateProductTrack(
        state.trackedProducts,
        state.activeTab,
        { id: action.payload.id },
        { checked: newCheckedState }
      )
      const numberChecked = getNumberOfCheckedTracks(
        updatedTrackedProducts,
        state.activeTab
      )
      return {
        ...state,
        checkAll:
          numberChecked === category.products.length ||
          (newCheckedState && !isEmpty(state.asinSearchTerm)),
        trackedProducts: updatedTrackedProducts,
        numberChecked
      }
    }
    case type.TOGGLE_CHECK_ALL_PRODUCT_TRACKS: {
      const newCheckedState = getShowState(state.checkAll)
      const updatedTrackedProducts = updateAllProductTracks(state, {
        checked: newCheckedState
      })
      const numberChecked = getNumberOfCheckedTracks(
        updatedTrackedProducts,
        state.activeTab
      )

      return {
        ...state,
        checkAll: newCheckedState,
        trackedProducts: updatedTrackedProducts,
        numberChecked
      }
    }
    case type.RESET_CHECKED_PRODUCTS: {
      const updatedTrackedProducts = state.trackedProducts.map(category => {
        const products = updateAllInArray(category.products, {
          checked: false
        })
        return { ...category, products }
      })

      return {
        ...state,
        checkAll: false,
        numberChecked: 0,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.TOGGLE_CHECK_ALL_RECOMMENDED_PRODUCTS: {
      const newCheckedState = getShowState(state.checkAllRecommended)
      const updatedRecommendedProducts = updateAllInArray(
        state.recommendedProducts,
        { checked: newCheckedState }
      )
      const numberChecked = getNumberOfCheckedRecommendedProducts(
        updatedRecommendedProducts
      )

      return {
        ...state,
        checkAllRecommended: newCheckedState,
        numberCheckedRecommended: numberChecked,
        recommendedProducts: updatedRecommendedProducts
      }
    }
    case type.RESET_CHECKED_RECOMMENDED_PRODUCTS: {
      const updatedRecommendedProducts = updateAllInArray(
        state.recommendedProducts,
        { checked: false }
      )

      return {
        ...state,
        checkAllRecommended: false,
        numberCheckedRecommended: 0,
        recommendedProducts: updatedRecommendedProducts
      }
    }
    case type.TOGGLE_CHECK_RECOMMENDED_PRODUCT: {
      const newCheckedState = getShowState(action.payload.checked)

      const updatedRecommendedProducts = updateInArray(
        state.recommendedProducts,
        { id: action.payload.id },
        { checked: newCheckedState }
      )

      const numberChecked = getNumberOfCheckedRecommendedProducts(
        updatedRecommendedProducts
      )

      return {
        ...state,
        checkAllRecommended: numberChecked === state.recommendedProducts.length,
        recommendedProducts: updatedRecommendedProducts,
        numberCheckedRecommended: numberChecked
      }
    }
    case type.PENDING_AVERAGES: {
      const updatedTrackedProducts = updateProductTrack(
        state.trackedProducts,
        action.payload.categoryName || state.activeTab,
        { id: action.payload.id },
        { isLoading: true }
      )
      return {
        ...state,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.SELECT_CHART_TYPE: {
      const updatedTrackedProducts = updateProductTrack(
        state.trackedProducts,
        state.activeTab,
        { id: action.payload.id },
        { chartType: action.payload.chartType }
      )
      return {
        ...state,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.FAILED_GET_TRACKER_CHART_DATA: {
      const updatedTrackedProducts = updateProductTrack(
        state.trackedProducts,
        action.payload.categoryName || state.activeTab,
        { id: action.payload.id },
        { isLoading: false }
      )
      return {
        ...state,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.COMPLETED_GET_TRACKER_CHART_DATA: {
      let updatedTrackedProducts = updateProductTrack(
        state.trackedProducts,
        action.payload.categoryName || state.activeTab,
        { id: action.payload.id },
        {
          ...action.payload.productData,
          isLoading: false
        }
      )

      if (action.payload.reloadCategoryAverages) {
        updatedTrackedProducts = updateCategoryAverages(
          updatedTrackedProducts,
          action.payload.categoryName || state.activeTab
        )
      }

      return {
        ...state,
        trackedProducts: updatedTrackedProducts
      }
    }
    case type.SELECT_DATE_RANGE: {
      return {
        ...state,
        selectedMonths: action.payload?.value
      }
    }
    case type.SET_SELECTED_MONTHS: {
      return {
        ...state,
        selectedMonths: action.payload
      }
    }
    case SELECT_GRAPH_DATE_RANGE: {
      return {
        ...state,
        graphSelectedMonths: action.payload
      }
    }
    case TOGGLE_GRAPH_Y_AXIS: {
      return {
        ...state,
        graphHideYAxis: action.payload
      }
    }
    case SET_GRAPH_ACCESSORS: {
      return {
        ...state,
        graphAccessors: action.payload
      }
    }
    case type.SET_ACTIVE_TRACK_SIDECARD: {
      return {
        ...state,
        notes: {
          ...state.notes,
          product: action.payload,
          trackerNotes: initialState.notes.trackerNotes,
          selectedNote: initialState.notes.selectedNote
        }
      }
    }
    case type.SET_SELECTED_TRACKER_NOTE: {
      return {
        ...state,
        notes: {
          ...state.notes,
          newNote: initialState.notes.newNote,
          selectedNote: action.payload
        }
      }
    }
    case type.UPDATE_TRACKER_NOTE_FIELD: {
      let updatedTrackerNotes = updateAllInArray(state.notes.trackerNotes, {
        showForm: false
      })

      updatedTrackerNotes = updateInArray(
        updatedTrackerNotes,
        { id: action.payload.id },
        action.payload.props
      )
      return {
        ...state,
        notes: {
          ...state.notes,
          trackerNotes: updatedTrackerNotes
        }
      }
    }
    case type.COMPLETED_LOAD_TRACKER_NOTES: {
      const newSelectedNote =
        action.payload.selectedNote || initialState.notes.selectedNote

      const updatedTrackedProducts = updateProductTrack(
        state.trackedProducts,
        state.activeTab,
        { id: action.payload.id },
        { has_notes: action.payload.has_notes }
      )

      return {
        ...state,
        trackedProducts: updatedTrackedProducts,
        notes: {
          ...state.notes,
          newNote: { subject: '', body: '' },
          trackerNotes: action.payload.trackerNotes,
          selectedNote: newSelectedNote
        }
      }
    }

    case type.SORT_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommendedSort: {
          ...state.recommendedSort,
          column: action.column,
          direction: action.direction
        }
      }

    case type.PENDING_TRACKER_PRODUCT_SEARCH_PREVIEW:
      return {
        ...state,
        productSearchPreview: {
          ...state.productSearchPreview,
          isLoading: true,
          products: action.payload || []
        }
      }

    case type.COMPLETED_TRACKER_PRODUCT_SEARCH_PREVIEW:
      return {
        ...state,
        productSearchPreview: {
          ...state.productSearchPreview,
          isLoading: false,
          products: action.payload
        }
      }

    case PENDING_TRACKER_CSV_IMPORT:
      return {
        ...state,
        importCsv: {
          ...state.importCsv,
          isLoading: true,
          error: null
        }
      }

    case COMPLETED_TRACKER_CSV_IMPORT:
      return {
        ...state,
        importCsv: {
          ...state.importCsv,
          isLoading: false,
          error: action.payload.error
        }
      }

    case ADD_PENDING_TRACKS:
      return {
        ...state,
        pendingTracks: [...new Set([...state.pendingTracks, ...action.payload])]
      }

    case REMOVE_PENDING_TRACKS:
      return {
        ...state,
        pendingTracks: removeFromArray(state.pendingTracks, action.payload)
      }

    case RESET_PRODUCT_TRACKER: {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}

export default tracker
